<template>
  <div class="examinationEyesHome">
    <div class="exan-box">
      <ul class="left">
        <li v-if="qxarr.uf_baseoptometry" class="el-icon-caret-right" :class="xzstr=='uf_baseoptometry'?'xz':''" @click="teb(qxarr.uf_baseoptometry.idx, qxarr.uf_baseoptometry.lx)">裂隙灯检查<i v-if="tjk.lxd" class="el-icon-check
"></i></li>
        <li v-if="qxarr.uf_fundusoptometry && type" class="el-icon-caret-right" :class="xzstr=='uf_fundusoptometry'?'xz':''" @click="teb(qxarr.uf_fundusoptometry.idx, qxarr.uf_fundusoptometry.lx)">眼底检查<i v-if="tjk.ydjc" class="el-icon-check
"></i></li>
        <li v-if="qxarr.uf_pupiloptometry && type" class="el-icon-caret-right" :class="xzstr=='uf_pupiloptometry'?'xz':''" @click="teb(qxarr.uf_pupiloptometry.idx, qxarr.uf_pupiloptometry.lx)">医学验光<i v-if="tjk.yxyg" class="el-icon-check
"></i></li>
        <li v-if="qxarr.uf_frametry && type" class="el-icon-caret-right" :class="xzstr=='uf_frametry'?'xz':''" @click="teb(qxarr.uf_frametry.idx, qxarr.uf_frametry.lx)">框架试戴<i v-if="tjk.kjsd" class="el-icon-check
"></i></li>
        <li  class="el-icon-caret-right" v-if="ishowsgn.uf_visualfunction&&type" @click="$router.push({path: '/home/examinationEyesHome/ViewFunctionTest'})">视功能检查<i v-if="tjk.sgnjc" class="el-icon-check
"></i></li>
        <li class="el-icon-caret-right" v-if="0">检查结论</li>
        <li v-if="qxarr.uf_examination_fc" class="el-icon-caret-right" :class="xzstr=='uf_examination_fc'?'xz':''" @click="teb(qxarr.uf_examination_fc.idx, qxarr.uf_examination_fc.lx)">眼底相机<i v-if="tjk.ydxj" class="el-icon-check
"></i></li>
        <li v-if="qxarr.uf_examination_ctm" class="el-icon-caret-right" :class="xzstr=='uf_examination_ctm'?'xz':''" @click="teb(qxarr.uf_examination_ctm.idx, qxarr.uf_examination_ctm.lx)">角膜地形图<i v-if="tjk.jmdxt" class="el-icon-check
"></i></li>
        <li v-if="qxarr.uf_examination_om" class="el-icon-caret-right" :class="xzstr=='uf_examination_om'?'xz':''" @click="teb(qxarr.uf_examination_om.idx, qxarr.uf_examination_om.lx)">眼生物测量<i v-if="tjk.yswjc" class="el-icon-check
"></i></li>
        <li v-if="qxarr.uf_examination_sm" class="el-icon-caret-right" :class="xzstr=='uf_examination_sm'?'xz':''" @click="teb(qxarr.uf_examination_sm.idx, qxarr.uf_examination_sm.lx)">角膜内皮镜<i v-if="tjk.jpnmj" class="el-icon-check
"></i></li>
        <li v-if="qxarr.uf_examination_tfe" class="el-icon-caret-right" :class="xzstr=='uf_examination_tfe'?'xz':''" @click="teb(qxarr.uf_examination_tfe.idx, qxarr.uf_examination_tfe.lx)">泪膜检查<i v-if="tjk.lmjc" class="el-icon-check
"></i></li>
        <li v-if="qxarr.uf_examination_syn && type" class="el-icon-caret-right" :class="xzstr=='uf_examination_syn'?'xz':''" @click="teb(qxarr.uf_examination_syn.idx, qxarr.uf_examination_syn.lx)">同视机<i v-if="tjk.tsj" class="el-icon-check
"></i></li>
        <li v-if="qxarr.uf_examination_vf && type" class="el-icon-caret-right" :class="xzstr=='uf_examination_vf'?'xz':''" @click="teb(qxarr.uf_examination_vf.idx, qxarr.uf_examination_vf.lx)">视野<i v-if="tjk.sy" class="el-icon-check
"></i></li>
        <li v-if="qxarr.uf_examination_wi && type" class="el-icon-caret-right" :class="xzstr=='uf_examination_wi'?'xz':''" @click="teb(qxarr.uf_examination_wi.idx, qxarr.uf_examination_wi.lx)">像差分析仪<i v-if="tjk.xcfxy" class="el-icon-check
"></i></li>
        <li v-if="qxarr.uf_examination_fjj && type" class="el-icon-caret-right" :class="xzstr=='uf_examination_fjj'?'xz':''" @click="teb(qxarr.uf_examination_fjj.idx, qxarr.uf_examination_fjj.lx)">房角镜检查<i v-if="tjk.fjjjc" class="el-icon-check
"></i></li>
        <li v-if="qxarr.uf_examination_ubm && type" class="el-icon-caret-right" :class="xzstr=='uf_examination_ubm'?'xz':''" @click="teb(qxarr.uf_examination_ubm.idx, qxarr.uf_examination_ubm.lx)">UBM检查<i v-if="tjk.ubm" class="el-icon-check
"></i></li>
        <li v-if="qxarr.uf_examination_oct && type" class="el-icon-caret-right" :class="xzstr=='uf_examination_oct'?'xz':''" @click="teb(qxarr.uf_examination_oct.idx, qxarr.uf_examination_oct.lx)">OCT<i v-if="tjk.oct" class="el-icon-check
"></i></li>
        <li v-if="qxarr.uf_examination_oubao && type" class="el-icon-caret-right" :class="xzstr=='uf_examination_oubao'?'xz':''" @click="teb(qxarr.uf_examination_oubao.idx, qxarr.uf_examination_oubao.lx)">欧堡检查<i v-if="tjk.objc" class="el-icon-check
"></i></li>
        <li v-if="qxarr.uf_examination_mg && type" class="el-icon-caret-right" :class="xzstr=='uf_examination_mg'?'xz':''" @click="teb(qxarr.uf_examination_mg.idx, qxarr.uf_examination_mg.lx)">干眼分析<i v-if="tjk.gyfx" class="el-icon-check
"></i></li>
      </ul>
      <div class="rightp" ref="boxm">


        <Liexian v-if="qxarr.uf_baseoptometry" ref="LXD" class="border-b"></Liexian>

        <FundusTest ref="YDJC" v-if="qxarr.uf_fundusoptometry && type" class="border-b"></FundusTest>

        <div v-if="qxarr.uf_pupiloptometry && type" id="yg" class="border-b" style="width: 90%;margin: 0 auto;">
          <h3 style="padding-top: 50px;">小瞳验光</h3>
          <SmallPupilOptometry></SmallPupilOptometry>
          <Refraction :type="1"></Refraction>
        </div>

        <TryFramework v-if="qxarr.uf_frametry && type" style="padding-bottom: 2rem;" class="border-b"></TryFramework>

        <div style="width: 90%; height: 0; overflow: hidden; border: none; margin: 0!important; padding: 0;">
          <AIassessment></AIassessment>
          <PlanData></PlanData>
          <mePlan></mePlan>
        </div>

        <EyeCamera v-if="qxarr.uf_examination_fc" class="border-b"></EyeCamera>

        <CorneaMap  ref="jmdxt" v-if="qxarr.uf_examination_ctm" class="border-b"></CorneaMap>

        <EyeBiologyTest ref="yswjc" v-if="qxarr.uf_examination_om" class="border-b"></EyeBiologyTest>

        <CorneaNPJ v-if="qxarr.uf_examination_sm" class="border-b"></CorneaNPJ>

        <AppearsTest v-if="qxarr.uf_examination_tfe" class="border-b"></AppearsTest>

        <div v-if="qxarr.uf_examination_syn && type" class="border-b" style="width: 90%;margin: 0 auto;">
          <Tsj></Tsj>
        </div>

        <FieldVision v-if="qxarr.uf_examination_vf && type" class="border-b"></FieldVision>

        <AberrationAnalyze v-if="qxarr.uf_examination_wi && type" class="border-b"></AberrationAnalyze>


        <FJTEST class="border-b" v-if="qxarr.uf_examination_fjj && type"></FJTEST>
        <UBM class="border-b" v-if="qxarr.uf_examination_ubm && type"></UBM>
        <OCT class="border-b" v-if="qxarr.uf_examination_oct && type" ref="OCT"></OCT>
        <OBJC class="border-b" v-if="qxarr.uf_examination_oubao && type"></OBJC>


        <GYZL ref="GYFX" v-if="qxarr.uf_examination_mg && type"  class="border-b" style="width: 90%;margin: 0 auto;"></GYZL>

        <el-button class="add" type="primary" @click="saves">{{bt}}保存</el-button>



        <div style="height: 300px;"></div>

      </div>
    </div>


  </div>
</template>

<script>
import specialSurveys from '@/publicFn/specialSurveys'
// import pf from '@/publicFn/baseFn'
import {Loading} from "element-ui";
export default {
  name: "examinationEyesHome",
  data() {
    return {
      showset:false,
      idx: 0,
      allys: '',
      maps: new Map(),
      bt: '裂隙灯',
      saveMap: new Map(),
      showView: false,
      xzstr: 'uf_baseoptometry',
      qxarr: '',
      ishowsgn: {},
      TimeMap: new Map(),
      tjk: {
        lxd: false,
        ydjc: false,
        yxyg:false,
        kjsd: false,
        sgnjc: false,
        ydxj: false,
        jmdxt: false,
        yswjc: false,
        jpnmj: false,
        lmjc: false,
        tsj: false,
        sy: false,
        xcfxy: false,
        ubm: false,
        fjjjc: false,
        oct: false,
        objc: false,
        gyfx: false
      }
    }
  },
  props: {
    type: {
      default: 1  //当为1时是特检科，当为0时是从角膜接触镜过来
    }
  },
  watch: {
    idx(n, o) {
      this.bt = this.maps.get(this.xzstr)
      this.setTime(this.xzstr)
    },
    "$store.state.isbc.tjk": {
      deep: true,
      handler: function (n ,o) {
        // console.log( n )
        this.tjk = JSON.parse(JSON.stringify(n))
      }
    },
    "$store.state.isShow.keyh": {
      handler(n, o) {
        // console.dir(document.body.offsetHeight)
        // console.dir(this.$refs.boxs)
        if (n > document.body.offsetHeight - 290) {
          this.$refs.boxm.scrollTop = this.$refs.boxm.scrollTop + (n - (document.body.offsetHeight - 290)) + 30
        }
      }
    }
  },
  created() {

    this._api.eyeTest.getBaseTest()
        .then(res => {
          // console.log(res)
          if (res.GetListResult && res.GetListResult.length) {
            let obj = res.GetListResult[0]
            obj.S_OP_Json = this._clJson(obj.S_OP_Json)
            obj.S_OP_Json.S_OP_EC_ChooseId = this.$store.state.physicianVisits.xzConsulting
            this.$store.commit('eyeTest/uplxd', JSON.parse(JSON.stringify(obj)))
          }
        })

    this.isshow()
    this.idx = 1
    this.ishowsgn = this.$store.state.users.jurisdiction
    this.getjc()

  },
  mounted() {
    this.$refs.boxm.addEventListener('scroll', this._jl(this.boxbh, 300))
    this.allys = document.getElementsByClassName('border-b')
    this.maps.set("uf_baseoptometry", '裂隙灯')
    this.maps.set('uf_fundusoptometry', '眼底检查')
    this.maps.set("uf_pupiloptometry", '医学验光')
    this.maps.set("uf_frametry", '试戴框架')
    // this.maps.set(6, '检查结论')
    this.maps.set("uf_examination_fc", '眼底相机')
    this.maps.set("uf_examination_ctm", '角膜地形图')
    this.maps.set("uf_examination_om", '眼生物测量')
    this.maps.set("uf_examination_sm", '角膜内皮镜')
    this.maps.set("uf_examination_tfe", '泪膜检查')
    this.maps.set("uf_examination_syn", '同视机')
    this.maps.set("uf_examination_vf", '视野')
    this.maps.set("uf_examination_wi", '像差分析仪')
    this.maps.set('uf_examination_oct', 'OCT')
    this.maps.set('uf_examination_mg', '干眼分析')
    this.maps.set('uf_examination_oct', 'OCT')
    // this.maps.set('uf_examination_oct', '')


    this.saveMap.set("uf_baseoptometry", specialSurveys.saveEyeBuTest)
    this.saveMap.set('uf_fundusoptometry', specialSurveys.saveEyeBuTest)
    this.saveMap.set("uf_pupiloptometry", specialSurveys.saveRefractive)
    this.saveMap.set("uf_frametry", this._api.eyeTest.saveTryData)
    this.saveMap.set("uf_examination_fc", this._api.eyeTest.saveEyeCamera)
    // this.saveMap.set("uf_examination_ctm", this._api.eyeTest.saveCorneaMap)
    // this.saveMap.set("uf_examination_om", this._api.eyeTest.saveEyeBiologyTest)
    this.saveMap.set("uf_examination_sm", this._api.eyeTest.saveCorneaNPJ)
    this.saveMap.set("uf_examination_tfe", this._api.eyeTest.saveAppearsTest)
    this.saveMap.set("uf_examination_syn", this._api.eyeTest.saveTsj)
    this.saveMap.set("uf_examination_vf", this._api.eyeTest.saveFieldVision)
    this.saveMap.set("uf_examination_wi", this._api.eyeTest.saveAberrationAnalyze)
    this.saveMap.set('uf_examination_mg', 'GYFX')



    this.TimeMap.set('uf_baseoptometry', 'EC_ophtha_SL')
    this.TimeMap.set('uf_fundusoptometry', 'EC_fundusCheck')
    this.TimeMap.set('uf_pupiloptometry', 'EC_computerOptometry')
    // this.TimeMap.set('uf_frametry', 'EC_ophtha_SL')
    this.TimeMap.set('uf_examination_fc', 'EC_ophtha_FC')
    this.TimeMap.set('uf_examination_ctm', 'EC_ophtha_CTM')
    this.TimeMap.set('uf_examination_om', 'EC_ophtha_OM')
    this.TimeMap.set('uf_examination_sm', 'EC_ophtha_SM')
    this.TimeMap.set('uf_examination_tfe', 'EC_ophtha_TFE')
    this.TimeMap.set('uf_examination_syn', 'EC_ophtha_SYN')
    this.TimeMap.set('uf_examination_vf', 'EC_ophtha_VF')
    this.TimeMap.set('uf_examination_wi', 'EC_ophtha_WI')


    if (this.$route.query.idx && this.$route.query.str) {
      this.teb(this.$route.query.idx, this.$route.query.str)
    }

  },
  methods: {
    boxbh() {
      let top = this.$refs.boxm.scrollTop
      this.clScrollTop(top)
    },
    teb(idx, str) {
      this.idx = idx
      this.xzstr = str
      // console.log(idx)
      this.$refs.boxm.scrollTop = this.getDivH(idx-1) + 150
    },

    getDivH(idx, xs=1)  {
      if (idx == 0) return 0
      if (idx > this.allys.length) return 0
      let h = 0
      for (let i = 0; i < idx; i++) {
        h += this.allys[i].offsetHeight
      }
      return h*xs-150
    },

    clScrollTop(h) {
      let tim = setTimeout(() => {
        clearTimeout(tim)

        if (h < this.getDivH(1)) {
          this.idx = 1
        }else if (h >= this.getDivH(1) && h < this.getDivH(2) ) {
          this.idx = 2
        }else if (h >= this.getDivH(2) && h < this.getDivH(3)) {
          this.idx = 3
        }else if (h >= this.getDivH(3) && h < this.getDivH(4)) {
          this.idx = 4
        }else if (h >= this.getDivH(4) && h < this. getDivH(5)) {
          this.idx = 5
        }else if (h >= this.getDivH(5) && h < this.getDivH(6)) {
          this.idx = 6
        }else if (h >= this.getDivH(6) && h < this.getDivH(7)) {
          this.idx = 7
        }else if (h >= this.getDivH(7) && h < this.getDivH(8)) {
          this.idx = 8
        }else if (h >= this.getDivH(8) && h < this.getDivH(9)) {
          this.idx = 9
        }else if (h >= this.getDivH(9) && h < this.getDivH(10)) {
          this.idx = 10
        }else if (h >= this.getDivH(10) && h < this.getDivH(11)) {
          this.idx = 11
        }else if (h >= this.getDivH(11) && h < this.getDivH(12)) {
          this.idx = 12
        }else if (h >= this.getDivH(12) && h < this.getDivH(13)) {
          this.idx = 13
        }else if (h >= this.getDivH(13) && h < this.getDivH(14)) {
          this.idx = 14
        }else if (h >= this.getDivH(14) && h < this.getDivH(15)) {
          this.idx = 15
        } else if (h >= this.getDivH(15) && h < this.getDivH(16)) {
          // console.log(211111)
          this.idx = 16
        } else if (h >= this.getDivH(16)-50) {
          // console.log(211111)
          this.idx = 17
        }



        for (let k in this.qxarr) {
          if (this.qxarr[k].idx == this.idx) {
            this.xzstr = this.qxarr[k].lx
            break
          }
        }
      }, 200)
    },

    saves() {
      if (this.xzstr === 'uf_baseoptometry') {
        this.$refs.LXD.save()
        return
      }
      if (this.xzstr ==='uf_fundusoptometry') {
        this.$refs.YDJC.save()
        return
      }
      if (this.xzstr === 'uf_examination_oct') {
        this.$refs.OCT.save()
        return
      }
      if (this.xzstr === 'uf_examination_fjj' || this.xzstr === 'uf_examination_oct'  || this.xzstr === 'uf_examination_ubm' ) {
        return this.$alert('保存成功', '提示')
      }
      if (this.xzstr === 'uf_examination_om') {
        this.$refs.yswjc.save()
        return
      }
      if (this.xzstr === 'uf_examination_ctm') {
        this.$refs.jmdxt.save()
        return
      }
      if (this.xzstr === 'uf_baseoptometry' || this.xzstr === 'uf_fundusoptometry') {
        this.xzstr === 'uf_baseoptometry'?this.saveMap.get(this.xzstr)('lxd'):this.saveMap.get(this.xzstr)('ydjc')

        return;
      }


      if (this.xzstr == 'uf_examination_mg') {
        this.$refs.GYFX.save()
        return
      }

      if (this.saveMap.get(this.xzstr)) {
        if (this.xzstr === 'uf_pupiloptometry') {
          let yg = document.getElementById('yg'),
              arrcw = yg.getElementsByClassName('cw')
          if (this.$store.state.Time.timeObj.EC_computerOptometry.ENTER != 0) {
            let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj.EC_computerOptometry.ENTER)/1000 + Number(this.$store.state.Time.timeObj.EC_computerOptometry.EXIT)
            this._api.publicApi.addTime('EC_computerOptometry,EC_subjectiveOptometry,EC_sliceOptometry', times)
            this.jsTime(this.TimeMap.get(this.xzstr), 1)
          }
          if (arrcw.length > 0) {
            this.$confirm('请复核红色区域数据是否异常！', '提示', {
              confirmButtonText: '继续提交',
              cancelButtonText: '取消',
              type: 'warning'
            })
                .then(() => {
                  this.saveMap.get(this.xzstr)()
                }).catch(() => {
            });
          }else {
            this.saveMap.get(this.xzstr)()
          }
        }else {
          // console.log(this.saveMap.get(this.xzstr))
          this.saveMap.get(this.xzstr)()
          if (this.xzstr == 'uf_frametry') return;
          if (this.$store.state.Time.timeObj[this.TimeMap.get(this.xzstr)].ENTER != 0) {
            let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj[this.TimeMap.get(this.xzstr)].ENTER)/1000 + Number(this.$store.state.Time.timeObj[this.TimeMap.get(this.xzstr)].EXIT)
            this._api.publicApi.addTime(this.TimeMap.get(this.xzstr), times)
            this.jsTime(this.TimeMap.get(this.xzstr), 1)
          }
        }
      }


    },

    isshow() {
      let obj = this.$store.state.users.jurisdiction,
          arr = [],
          items = {
            uf_baseoptometry: '',
            uf_fundusoptometry: '',
            uf_pupiloptometry: '',
            uf_frametry: '',
            uf_examination_fc: '',
            uf_examination_ctm: '',
            uf_examination_om: '',
            uf_examination_sm: '',
            uf_examination_tfe: '',
            uf_examination_syn: '',
            uf_examination_vf: '',
            uf_examination_wi: '',
            uf_examination_fjj: '',
            uf_examination_ubm: '',
            uf_examination_oct: '',
            uf_examination_oubao: '',
            uf_examination_mg: '' //干眼分析
          }
          // console.log(obj)
      if (obj.uf_baseoptometry) arr.push({lx: obj.uf_baseoptometry});
      if (obj.uf_fundusoptometry) arr.push({lx: obj.uf_fundusoptometry});
      if (obj.uf_pupiloptometry) arr.push({lx: obj.uf_pupiloptometry});
      if (obj.uf_frametry) arr.push({lx: obj.uf_frametry});
      if (obj.uf_examination_fc) arr.push({lx: obj.uf_examination_fc});
      if (obj.uf_examination_ctm) arr.push({lx: obj.uf_examination_ctm});
      if (obj.uf_examination_om) arr.push({lx: obj.uf_examination_om});
      if (obj.uf_examination_sm) arr.push({lx: obj.uf_examination_sm});
      if (obj.uf_examination_tfe) arr.push({lx: obj.uf_examination_tfe});
      if (obj.uf_examination_syn) arr.push({lx: obj.uf_examination_syn});
      if (obj.uf_examination_vf) arr.push({lx: obj.uf_examination_vf});
      if (obj.uf_examination_wi) arr.push({lx: obj.uf_examination_wi});
      if (obj.uf_examination_fjj) arr.push({lx: obj.uf_examination_fjj});
      if (obj.uf_examination_ubm) arr.push({lx: obj.uf_examination_ubm});
      if (obj.uf_examination_oct) arr.push({lx: obj.uf_examination_oct});
      if (obj.uf_examination_oubao) arr.push({lx: obj.uf_examination_oubao});
      if (obj.uf_examination_mg) arr.push({lx: obj.uf_examination_mg});
      arr.forEach((item, idx) => {
        item.idx = idx+1
      })
      arr.forEach(item => {
        items[item.lx] = item
      })

      // console.log(items)

      this.qxarr = items
      // console.log(this.qxarr.uf_examination_fjj)
      items = null
      obj = null
    },


    setTime(str) {
      let arr =  Array.from(this.TimeMap.keys())
      for (let i = 0; i <arr.length; i++) {
        if (str == arr[i]) {
          this.cshTime(this.TimeMap.get(str))
        }else {
          this.jsTime(this.TimeMap.get(arr[i]))
        }
      }
    },
    cshTime(keys) {
      // console.log(this.$store.state.Time.timeObj[keys].EXIT)
      this.$store.commit('Time/upTimeObj', {
        keys: keys,
        val: {
          ENTER: (new Date()).valueOf(),
          EXIT: this.$store.state.Time.timeObj[keys].EXIT
        }
      })
    },
    jsTime(keys,types=0) {
      if (types) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: 0,
            EXIT: 0
          }
        })
        return
      }
      if (this.$store.state.Time.timeObj[keys].ENTER !== 0) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: (new Date()).valueOf(),
            EXIT: ((new Date()).valueOf() - this.$store.state.Time.timeObj[keys].ENTER)/1000 + Number(this.$store.state.Time.timeObj[keys].EXIT)
          }
        })
      }
    },

    getjc() {
      this._api.publicApi.getExaminationFinish()
          .then(res => {
            if (res.GetResult && res.GetResult.ChooseStep) {
              let arr = res.GetResult.ChooseStep.split(',')
              for (let i = 0; i < arr.length; i++) {
                switch (arr[i]) {
                  case 'S_OP_EC_part2_isDone':
                    this.$store.commit('isbc/uptjk', {
                      val: true,
                      keys: 'ydjc'
                    })
                    break
                  case 'S_OP_EC_part3_isDone':
                    this.$store.commit('isbc/uptjk', {
                      val: true,
                      keys: 'ydjc'
                    })
                    break
                  case 'Refractive':
                    this.$store.commit('isbc/uptjk', {
                      val: true,
                      keys: 'yxyg'
                    })
                    break
                  case 'VisionFunction':
                    this.$store.commit('isbc/uptjk', {
                      val: true,
                      keys: 'sgnjc'
                    })
                    break
                  case 'CL_RE_FC':
                    this.$store.commit('isbc/uptjk', {
                      val: true,
                      keys: 'ydxj'
                    })
                    break
                  case 'CL_RE_CTM':
                    this.$store.commit('isbc/uptjk', {
                      val: true,
                      keys: 'jmdxt'
                    })
                    break
                  case 'CL_RE_OM':
                    this.$store.commit('isbc/uptjk', {
                      val: true,
                      keys: 'yswcl'
                    })
                    break
                  case 'CL_RE_SM':
                    this.$store.commit('isbc/uptjk', {
                      val: true,
                      keys: 'jpnmj'
                    })
                    break
                  case 'CL_RE_TFE':
                    this.$store.commit('isbc/uptjk', {
                      val: true,
                      keys: 'lmjc'
                    })
                    break
                  case 'CL_RE_SYN':
                    this.$store.commit('isbc/uptjk', {
                      val: true,
                      keys: 'tsj'
                    })
                    break
                  case 'CL_RE_VF':
                    this.$store.commit('isbc/uptjk', {
                      val: true,
                      keys: 'sy'
                    })
                    break
                  case 'CL_RE_WI':
                    this.$store.commit('isbc/uptjk', {
                      val: true,
                      keys: 'xcfxy'
                    })
                    break
                  // case 'CL_RE_WI':
                  //   this.$store.commit('isbc/uptjk', {
                  //     val: true,
                  //     keys: 'xcfxy'
                  //   })
                  //   break
                  // case 'CL_RE_WI':
                  //   this.$store.commit('isbc/uptjk', {
                  //     val: true,
                  //     keys: 'xcfxy'
                  //   })
                  //   break
                }
              }
            }
          })
    },

  },
  components: {
    GYZL: () => import('@/views/specialSurvey/dryEyeTreatment'),
    Liexian: () => import('@/components/specialSurveys/eyePart/liexian'),
    FundusTest: () => import('@/components/specialSurveys/eyePart/fundusTest'),
    Refraction: () => import('@/components/specialSurveys/refractive/refraction'),
    SmallPupilOptometry: () => import('@/components/specialSurveys/refractive/SmallPupilOptometry'),
    TryFramework: () => import('@/components/specialSurveys/refractive/TryFrameworkContent'),
    AIassessment: () => import('@/components/specialSurveys/AiAssessment/AIassessment'),
    PlanData: () => import('@/components/specialSurveys/AiAssessment/planData'),
    mePlan: () => import('@/components/specialSurveys/AiAssessment/mePlan'),
    EyeCamera: () => import('@/views/specialSurvey/eyeCamera'),
    CorneaMap: () => import('@/views/specialSurvey/corneaMap'),
    EyeBiologyTest: () => import('@/views/specialSurvey/eyeBiologyTest'),
    CorneaNPJ: () => import('@/views/specialSurvey/corneaNPJ'),
    AppearsTest: () => import('@/views/specialSurvey/appearsTest'),
    Tsj: () => import('@/components/specialSurveys/tsj/tsjTestTable'),
    FieldVision: () => import('@/views/specialSurvey/fieldVision'),
    AberrationAnalyze: () => import('@/views/specialSurvey/AberrationAnalyzer'),
    ViewFunctionTest: () => import('@/views/specialSurvey/ViewFunctionTest'),
    FJTEST: () => import("@/components/specialSurveys/Fjtest"),
    UBM: () => import("@/components/specialSurveys/Ubm"),
    OCT: () => import("@/components/specialSurveys/OCT"),
    OBJC: () => import("@/components/specialSurveys/aubergInspection"),
  },
  beforeDestroy() {
    if (this.$store.state.Time.timeObj[this.TimeMap.get(this.xzstr)].ENTER != 0) {
      this.jsTime(this.TimeMap.get(this.xzstr))
    }
  }
}
</script>

<style scoped lang="scss">
  .border-b {
    border-bottom: 1px dotted #5a5959;
    padding-bottom: 0.3rem;
    padding-top: 1rem;
  }
  .exan-box {
    width: 100vw;
    display: flex;
    justify-content: start;
    height: 76vh;
    overflow: hidden;
  }
  .rightp {
    width: 87vw;
    height: 76vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .left {
    width: 12vw;
    font-size: 14px;
    text-align: left;
    border-right: 1px solid #bcbaba;
    overflow: hidden;
    overflow-y: auto;
    li {
      padding: 0.2rem 0.2rem;
      display: block;
      cursor: pointer;
      position: relative;
      &:hover{
        background: #e7e7e7;
      }
      i {
        position: absolute;
        right: 1vw;
        font-weight: bold;
      }
    }
    .xz {background: #e7e7e7}
  }
  //.left::-webkit-scrollbar { width: 0 !important }
  //.rightp::-webkit-scrollbar { width: 0 !important }
  .add {
    position: fixed;
    right: 0.1rem;
    bottom: 3rem;
  }
</style>
